<template>
  <div>
    <div class="bluehead">
      <div class="row">
        <div style="text-align: center" class="col"><h5>EVENEMENTS</h5></div>
        <a class="btn-menu" href="">
          <router-link
            class="nav-link routlink rounded-circle p-0 mx-1"
            style="background-color: #0000fe"
            :to="`/evenements/${0} `"
          >
            <svg
            style="color:white"
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="white"
              class="bi bi-plus icone-sup"
              viewBox="0 0 16 16"
            >
              <path
                d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"
              />
            </svg>
            <!--span class="text-white icone-sup">+</span-->
          </router-link>
        </a>
        <a v-if="typeCompte == 'Super Admin'" class="btn-menu" href="">
          <router-link
            class="nav-link routlink rounded-circle p-0 mx-1"
            style="background-color: #0000fe"
            :to="`/evenements/${modification} `"
          >
            <svg
              style="color: white"
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="white"
              class="bi bi-pencil-fill icone-sup"
              viewBox="0 0 16 16"
            >
              <path
                d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z"
              />
            </svg>
          </router-link>
        </a>
      </div>
    </div>
    <center v-if="echec">
      <div>Probleme de connexion.Reéssayer svp</div>
      <svg
        @click="
          echec = '0';
          recharger();
        "
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        style="color: blue; cursor: pointer"
        fill="currentColor"
        class="bi bi-bootstrap-reboot"
        viewBox="0 0 16 16"
      >
        <path
          d="M1.161 8a6.84 6.84 0 1 0 6.842-6.84.58.58 0 1 1 0-1.16 8 8 0 1 1-6.556 3.412l-.663-.577a.58.58 0 0 1 .227-.997l2.52-.69a.58.58 0 0 1 .728.633l-.332 2.592a.58.58 0 0 1-.956.364l-.643-.56A6.812 6.812 0 0 0 1.16 8tableau"
        />
        <path
          d="M6.641 11.671V8.843h1.57l1.498 2.828h1.314L9.377 8.665c.897-.3 1.427-1.106 1.427-2.1 0-1.37-.943-2.246-2.456-2.246H5.5v7.352h1.141tableaum0-3.75V5.277h1.57c.881 0 1.416.499 1.416 1.32 0 .84-.504 1.324-1.386 1.324h-1.6tableau"
        />
      </svg>
      <div
        v-if="echec == '0'"
        class="spinner-border text-primary"
        role="status"
      ></div>
    </center>

    <section v-else class="liste text-center container-lg box-center">
      <div v-if="!noresult" class="row">
        <div class="col-3 xorder2 order-2">
          <div class="search">
            <span style="display: flex">
              <input
                id="recherche"
                v-model="search"
                type="search"
                class="form-control"
                placeholder="Rechercher..."
                aria-describedby="passwordHelpBlock"
              />
              <!--div @click="recH() " class="btn btn-primary">
                    <svg  xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-search" viewBox="0 0 16 16">
                      <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"/>
                    </svg>
                  </!--div-->
            </span>
          </div>
          <div class="mt-2">
            Nombre de résultat(s): {{ filteredEvenement.length }}
          </div>
          <hr />
          <div v-if="chargement">
            <div
              v-if="recharg"
              class="spinner-border text-primary"
              style="width: 2rem; height: 2rem"
              role="status"
            >
              <span class="visually-hidden"></span>
            </div>
            <div v-else class="div">Echec. Veillez reesayez</div>
          </div>

          <div class="list-scrool">
            <div
              style="text-align: center"
              v-for="item in filteredEvenement"
              :key="item.index"
              class="border blanc"
            >
              <span
                @click="
                  (selectitem = item),
                    (event_id = selectitem.id),
                    ExporterElement(item)
                "
                class="btn col-12"
              >
                <img
                  src="../assets/png/billicon.001.png"
                  width="30"
                  height="30"
                  alt=""
                  srcset=""
                />
                <div class="pointille">{{ item.titre }}</div>
              </span>
            </div>
          </div>
        </div>
        <div v-if="chargement" class="col page-vide order-1">
          <div class="spinner-grow text-info" role="status">
            <span class="sr-only">Loading...</span>
          </div>
          <div class="spinner-grow text-info" role="status">
            <span class="sr-only">Loading...</span>
          </div>
          <div class="spinner-grow text-info" role="status">
            <span class="sr-only">Loading...</span>
          </div>
          <p>chargement des données...</p>
        </div>
        <div v-else class="col xorder1 order-1">
          <div
            style="text-align: center"
            v-if="erreur"
            class="alert alert-danger"
            role="alert"
          >
            {{ msg }}
          </div>
          <div
            style="text-align: center"
            v-else-if="msg2 !== ''"
            class="alert alert-success"
            role="alert"
          >
            {{ msg2 }}
          </div>
          <section class="details">
            <div v-if="typeCompte == 'Super Admin'" class="delete float-right">
              <svg
                data-bs-toggle="modal"
                data-bs-target="#staticBackdrop1"
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                fill="currentColor"
                class="bi icone-sup bi-trash-fill"
                viewBox="0 0 16 16"
              >
                <path
                  d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 
                  4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z"
                />
              </svg>
            </div>

            <div class="row">
              <div class="col grandeicone">
                <img
                  src="../assets/png/billicon.001.png"
                  width="150"
                  height="150"
                  alt=""
                  srcset=""
                />
              </div>
              <div class="col">
                <span v-if="selectitem.lieu == ''">
                  Lieu: <span class="element"> Non Fourni </span>
                </span>
                <span v-else>
                  Lieu:
                  <span class="element"> {{ selectitem.lieu }} </span> </span
                ><br />

                <div class="description my-2 ml-0" v-if="selectitem.description == ''">
                  <li class="list-item font-weight-bold">
                    {{ selectitem.titre }}
                  </li>
                  Description Non Fournie
                </div>
                <div class="description my-2 ml-0" v-else>
                  <li class="list-item font-weight-bold">
                    {{ selectitem.titre }}
                  </li>
                  {{ selectitem.description }}
                </div>
                <hr />

                <span v-if="selectitem.type_activite == ''">
                  Type: <span class="element"> Non Fourni </span>
                </span>
                <span v-else>
                  Type:
                  <span class="element">
                    {{ selectitem.type_activite }}
                  </span> </span
                ><br />

                <span v-if="selectitem.frequence == ''">
                  Fréquence: <span class="element"> Non Fourni </span>
                </span>
                <span v-else>
                  Fréquence:
                  <span class="element">
                    {{ selectitem.frequence }}
                  </span> </span
                ><br />

                <span v-if="selectitem.nbr_participants == ''">
                  Nombre de Participants:
                  <span class="element"> Non Fourni </span>
                </span>
                <span v-else>
                  Nombre de Participants:
                  <span class="element">
                    {{ selectitem.nbr_participants }}
                  </span> </span
                ><br />

                <span v-if="selectitem.date_enregistrement == ''">
                  Enregistré le: <span class="element"> Non Fourni </span>
                </span>
                <span v-else>
                  Enregistré le:
                  <span class="element">
                    {{ selectitem.date_enregistrement }}
                  </span> </span
                ><br />

                <span v-if="selectitem.date_debut == ''">
                  Début: <span class="element"> Non Fourni </span>
                </span>
                <span v-else>
                  Début:
                  <span class="element">
                    {{ selectitem.date_debut }}
                  </span> </span
                ><br />

                <span v-if="selectitem.date_fin == ''">
                  Fin: <span class="element"> Non Fourni </span>
                </span>
                <span v-else>
                  Fin: <span class="element"> {{ selectitem.date_fin }} </span>
                </span>
              </div>
            </div>
            <hr />
          </section>
          <!-- <div v-if="selectitem.infos_participant ==!null">PARTICIPANTS ( {{ selectitem.nbr_participants }} ) </div> -->
          <!-- Modal pour ajouter participant-->
          <div
            class="modal fade"
            id="exampleModal"
            tabindex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="exampleModalLabel">Membres</h5>
                  <div
                    @click="makeGetRequest()"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      fill="currentColor"
                      class="bi bi-x"
                      viewBox="0 0 16 16"
                    >
                      <path
                        d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 
                          8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"
                      />
                    </svg>
                  </div>
                </div>
                <div class="modal-body modal-ajout-participant">
                  <table class="table">
                    <tbody v-if="loader">
                      <center>
                        <div
                          class="spinner-border text-primary"
                          role="status"
                        ></div>
                      </center>
                    </tbody>
                    <tbody v-else>
                      <tr v-for="item in all" :key="item.index">
                        <td>
                          <img
                            width="20"
                            height="20"
                            src="../assets/png/005-user.png"
                            alt=""
                          />
                        </td>
                        <th scope="row">{{ item.nom }}</th>
                        <th scope="row">{{ item.prenom }}</th>
                        <td>
                          <svg
                            @click="ajoutParticipant(item.id), (loader = true)"
                            style="color: green"
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            class="bi bi-person-plus-fill"
                            viewBox="0 0 16 16"
                          >
                            <path
                              d="M1 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"
                            />
                            <path
                              fill-rule="evenodd"
                              d="M13.5 5a.5.5 0 0 1 .5.5V7h1.5a.5.5 0 0 1 0 1H14v1.5a.5.5 0 0 1-1 0V8h-1.5a.5.5 0 0 1 0-1H13V5.5a.5.5 0 0 1 .5-.5z"
                            />
                          </svg>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="modal-footer">
                  <button
                    @click="makeGetRequest()"
                    type="button"
                    class="btn btn-primary"
                    data-bs-dismiss="modal"
                  >
                    Fermer
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="ajout-participant btnfloat float-right">
            <svg
              data-bs-toggle="modal"
              data-bs-target="#exampleModal"
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi icone-sup tn bi-person-plus-fill"
              viewBox="0 0 16 16"
            >
              <path
                d="M1 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"
              />
              <path
                fill-rule="evenodd"
                d="M13.5 5a.5.5 0 0 1 .5.5V7h1.5a.5.5 0 0 1 0 1H14v1.5a.5.5 0 0 1-1 0V8h-1.5a.5.5 0 0 1 0-1H13V5.5a.5.5 0 0 1 .5-.5z"
              />
            </svg>
          </div>
          <section
            class="membreTab"
            v-if="(selectitem.infos_participant = !null)"
          >
            <div style="padding: 10px" class="row">
              <div
                style="margin: 2px"
                v-for="item in selectitem.participants"
                :key="item.index"
                class="border sombre"
              >
                <center
                  class="cartebtn"
                  @click="participant = item.infos_participant"
                  data-bs-toggle="modal"
                  data-bs-target="#staticBackdrop"
                >
                  <img
                    class="image-fit"
                    style="margin: 10px; border-radius: 100%"
                    v-if="item.infos_participant.lien_photo"
                    :src="photo + item.infos_participant.lien_photo"
                    alt="Photo"
                    width="50"
                    height="50"
                    srcset=""
                  />
                  <svg
                    style="margin: 10px"
                    v-else
                    xmlns="http://www.w3.org/2000/svg"
                    width="50"
                    height="50"
                    fill="currentColor"
                    class="bi color-icone bi-person-circle"
                    viewBox="0 0 16 16"
                  >
                    <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" />
                    <path
                      fill-rule="evenodd"
                      d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z"
                    />
                  </svg>
                  <span
                    @click="participant = item.infos_participant"
                    class="btn"
                    data-bs-toggle="modal"
                    data-bs-target="#staticBackdrop"
                  >
                    {{ item.infos_participant.prenom }}
                    {{ item.infos_participant.nom }}
                  </span>
                </center>
              </div>
            </div>
          </section>
          <!-- Modal pour détails du participant -->
          <div
            class="modal fade"
            id="staticBackdrop"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            tabindex="-1"
            aria-labelledby="staticBackdropLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="staticBackdropLabel">
                    {{ participant.prenom }} {{ participant.nom }}
                  </h5>
                  <svg
                    type="button"
                    @click="voir = true"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    fill="currentColor"
                    class="bi bi-x"
                    viewBox="0 0 16 16"
                  >
                    <path
                      d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"
                    />
                  </svg>
                </div>
                <div v-if="voir" class="modal-body modaltext">
                  <center v-if="MSG !== ''">
                    <div class="alert alert-danger">{{ MSG }}</div>
                  </center>

                  <div class="row">
                    <div class="col">
                      <img
                        class="image-fit"
                        style="margin: 10px; border-radius: 100%"
                        v-if="participant.lien_photo"
                        :src="photo + participant.lien_photo"
                        alt="Photo"
                        width="100"
                        height="100"
                        srcset=""
                      />
                      <svg
                        style="margin: 10px"
                        v-else
                        xmlns="http://www.w3.org/2000/svg"
                        width="100"
                        height="100"
                        fill="currentColor"
                        class="bi color-icone bi-person-circle"
                        viewBox="0 0 16 16"
                      >
                        <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" />
                        <path
                          fill-rule="evenodd"
                          d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z"
                        />
                      </svg>

                      <div>
                        <li class="list-item">
                          <span>
                            <a target="_blank" :href="Mail + participant.mail"
                              ><img
                                width="15"
                                height="15"
                                src="../assets/png/023-email.png"
                                alt=""
                            /></a>
                            <a
                              target="_blank"
                              :href="Lien + participant.facebook"
                              ><img
                                width="15"
                                height="15"
                                src="../assets/png/019-facebook-1.png"
                                alt=""
                            /></a>
                            <a
                              target="_blank"
                              :href="Lien + participant.whatsapp"
                              ><img
                                width="15"
                                height="15"
                                src="../assets/png/021-whatsapp.png"
                                alt=""
                            /></a>
                            <a
                              target="_blank"
                              :href="Lien + participant.linkedin"
                              ><img
                                width="15"
                                height="15"
                                src="../assets/png/022-linkedin.png"
                                alt=""
                            /></a>
                          </span>
                        </li>
                      </div>
                    </div>
                    <div style="text-align: start" class="row col">
                      <div v-if="participant.bureau == ''" class="col">
                        <div class="gras">Bureau</div>
                        Non Fourni
                      </div>
                      <div v-else class="col">
                        <div class="gras">Bureau</div>
                        {{ participant.bureau }}
                      </div>

                      <div v-if="participant.fonction == ''" class="col">
                        <div class="gras">Fonction</div>
                        Non Fourni
                      </div>
                      <div v-else class="col">
                        <div class="gras">Fonction</div>
                        {{ participant.fonction }}
                      </div>
                    </div>
                    <div style="text-align: start" class="row col">
                      <div v-if="participant.pays == ''" class="col">
                        <div class="gras">Pays</div>
                        Non Fourni
                      </div>
                      <div v-else class="col">
                        <div class="gras">Pays</div>
                        {{ participant.pays }}
                      </div>

                      <div v-if="participant.ville == ''" class="col">
                        <div class="gras">Ville</div>
                        Non Fourni
                      </div>
                      <div v-else class="col">
                        <div class="gras">Ville</div>
                        {{ participant.ville }}
                      </div>
                    </div>
                    <div style="text-align: start" class="row col">
                      <div v-if="participant.date_adhesion == ''" class="col">
                        <div class="gras">Adhésion</div>
                        Non Fourni
                      </div>
                      <div v-else class="col">
                        <div class="gras">Adhésion</div>
                        {{ participant.date_adhesion }}
                      </div>

                      <div v-if="participant.etat_cotisation == ''" class="col">
                        <div class="gras">Cotisation</div>
                        Non Fourni
                      </div>
                      <div v-else class="col">
                        <div class="gras">Cotisation</div>
                        {{ participant.etat_cotisation }}
                      </div>
                    </div>
                  </div>
                </div>
                <center v-else-if="voir == false">
                  <div class="alert" role="alert">
                    <h4 class="alert-heading">
                      <svg
                        style="color: red"
                        xmlns="http://www.w3.org/2000/svg"
                        width="50"
                        height="50"
                        fill="currentColor"
                        class="bi bi-exclamation-triangle"
                        viewBox="0 0 16 16"
                      >
                        <path
                          d="M7.938 2.016A.13.13 0 0 1 8.002 2a.13.13 0 0 1 .063.016.146.146 0 0 1 .054.057l6.857 11.667c.036.06.035.124.002.183a.163.163 0 0 1-.054.06.116.116 0 0 1-.066.017H1.146a.115.115 0 0 1-.066-.017.163.163 0 0 1-.054-.06.176.176 0 0 1 .002-.183L7.884 2.073a.147.147 0 0 1 .054-.057zm1.044-.45a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566z"
                        />
                        <path
                          d="M7.002 12a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 5.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995z"
                        /></svg
                      ><br />
                      Attention!!!
                    </h4>
                    <p>Ce participant sera retiré</p>
                    <p class="mb-0">
                      <button
                        @click="
                          voir = s;
                          id_membre = participant.id;
                          deleteMembre();
                        "
                        type="button"
                        class="btn btn-outline-danger"
                      >
                        Confirmer
                      </button>
                      <button
                        @click="voir = true"
                        type="button"
                        class="btn btn-outline-primary"
                      >
                        Annuler
                      </button>
                    </p>
                  </div>
                </center>
                <center v-else-if="voir == x">
                  <br />
                  <div
                    class="spinner-border text-primary"
                    style="width: 2rem; height: 2rem"
                    role="status"
                  >
                    <span class="visually-hidden"></span>
                  </div>
                </center>
                <center v-else-if="voir == 'echec'">
                  <div class="alert alert-success">{{ MSG }}</div>
                </center>
                <div class="modal-footer">
                  <button
                    v-if="voir"
                    @click="voir = false"
                    type="button"
                    class="btn btn-danger"
                  >
                    Retirer
                  </button>
                  <button
                    type="button"
                    @click="
                      exporteurM(participant.id);
                      voir = true;
                    "
                    class="btn btnmodal"
                    data-bs-dismiss="modal"
                  >
                    Voir plus
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="pasdelement">
        <div class="search">
          <span style="display: flex">
            <input
              id="recherche"
              v-model="search"
              type="search"
              class="form-control"
              placeholder="Rechercher..."
              aria-describedby="passwordHelpBlock"
            />
            <div @click="recH()" class="btn btn-primary">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-search"
                viewBox="0 0 16 16"
              >
                <path
                  d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"
                />
              </svg>
            </div>
          </span>
        </div>
        <hr />
        <h4>Aucun évenement trouvé</h4>
      </div>
    </section>
    <!-- Modal  pour l'ajout des membres à l'evenement-->
    <div
      class="modal fade"
      id="exampleModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Membres</h5>
            <div data-bs-dismiss="modal" aria-label="Close">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                fill="currentColor"
                class="bi bi-x"
                viewBox="0 0 16 16"
              >
                <path
                  d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 
                          8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"
                />
              </svg>
            </div>
          </div>
          <div class="modal-body modal-ajout-participant">
            <table class="table">
              <tbody>
                <tr v-for="item in all" :key="item.index">
                  <td>
                    <img
                      width="20"
                      height="20"
                      src="../assets/png/005-user.png"
                      alt=""
                    />
                  </td>
                  <th scope="row">{{ item.nom }}</th>
                  <th scope="row">{{ item.prenom }}</th>
                  <td>
                    <svg
                      v-if="ajt == false"
                      @click="ajoutParticipant(item.id)"
                      style="color: green"
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-person-plus-fill"
                      viewBox="0 0 16 16"
                    >
                      <path
                        d="M1 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"
                      />
                      <path
                        fill-rule="evenodd"
                        d="M13.5 5a.5.5 0 0 1 .5.5V7h1.5a.5.5 0 0 1 0 1H14v1.5a.5.5 0 0 1-1 0V8h-1.5a.5.5 0 0 1 0-1H13V5.5a.5.5 0 0 1 .5-.5z"
                      />
                    </svg>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-primary"
              data-bs-dismiss="modal"
            >
              Fermer
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal confirmation de suppression -->
    <div
      class="modal fade"
      id="staticBackdrop1"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabindex="-1"
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header bg-danger">
            <h5 class="modal-title" id="staticBackdropLabel">
              ATTENTION SUPPRESSION
            </h5>
            <svg
              type="button"
              data-bs-dismiss="modal"
              aria-label="Close"
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              fill="currentColor"
              class="bi bi-x"
              viewBox="0 0 16 16"
            >
              <path
                d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"
              />
            </svg>
          </div>
          <div class="modal-body modaltext">
            <div v-if="supprimer == false" class="row">
              <div style="text-align: center" class="col">
                <svg
                  style="color: red"
                  xmlns="http://www.w3.org/2000/svg"
                  width="50"
                  height="50"
                  fill="currentColor"
                  class="bi bi-exclamation-triangle"
                  viewBox="0 0 16 16"
                >
                  <path
                    d="M7.938 2.016A.13.13 0 0 1 8.002 2a.13.13 0 0 1 .063.016.146.146 0 0 1 .054.057l6.857 11.667c.036.06.035.124.002.183a.163.163 0 0 1-.054.06.116.116 0 0 1-.066.017H1.146a.115.115 0 0 1-.066-.017.163.163 0 0 1-.054-.06.176.176 0 0 1 .002-.183L7.884 2.073a.147.147 0 0 1 .054-.057zm1.044-.45a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566z"
                  />
                  <path
                    d="M7.002 12a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 5.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995z"
                  />
                </svg>
              </div>
              <div style="text-align: start" class="col-8">
                {{ selectitem.titre }} <br />
                {{ selectitem.description }}
              </div>
            </div>
          </div>
          <div v-if="supprimer == false" class="modal-footer">
            <button
              @click="del(), (supprimer = true)"
              type="button"
              class="btn btn-danger"
              data-bs-dismiss="modal"
            >
              Confirmer
            </button>
            <button type="button" class="btn btnmodal" data-bs-dismiss="modal">
              Annuler
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export var choix = "";
export var tabElemen = "";
import axios from "axios";
export default {
  name: "liste_evenement",
  data: () => {
    return {
      series: [44, 55, 13, 43, 22],
      chartOptions: {
        chart: {
          width: 380,
          type: "pie",
        },
        labels: ["Team A", "Team B", "Team C", "Team D", "Team E"],
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      },
      nom: "BILLY",
      prenom: "",
      naissance: "",
      genre: "",
      domaine: "",
      photo: "https://www.rab-tech.com/dev_mode/js/api/",
      profession: "",
      dateAdhesion: "",
      categorie: "",
      fonction: "",
      bureau: "",
      matricule: "",
      ville: "",
      localite: "",
      zone: "",
      telephone: "",
      mail: "",
      whatsapp: "",
      facebook: "",
      instagram: "",
      skype: "",
      personnalite: "",
      organisation: "",
      fonctionOrganisation: "",
      date_enregistrement: "12/12/20",
      all: [],
      recharg: true,
      mod: false,
      Etatdeschamps: "",
      reponx: [],
      selectitem: "",
      Tel: "tel:",
      Mail: "mailto:",
      Lien: "",
      participant: "",
      chargement: true,
      supprimer: false,
      search: "",
      filtreur: "titre",
      reponx_code: "",
      modification: "1",
      event_id: "",
      loader: true,
      msg: "",
      erreur: false,
      msg2: "",
      ajt: false,
      id_membre: "",
      MSG: "",
      voir: true,
      id_import: "",
      echec: false,
      noresult: false,
      elementTitre: "",
      elementDescription: "",
      elementTypeActivite: "",
      elementLieu: "",
      typeCompte: "",
    };
  },
  methods: {
    firstElement: function () {
      this.selectitem = this.reponx[0];
      this.exporteur(this.selectitem);
      this.event_id = this.selectitem.id;
      this.allmembre();
    },
    ActiverChamps: function () {
      var champ = document.getElementById("champsD");
      champ.disabled = !champ.disabled;
    },
    ExporterElement(selection) {
      tabElemen = selection;
      console.log(tabElemen);
    },
    recharger() {
      //window.location.reload();
      this.makeGetRequest();
    },
    makeGetRequest: async function () {
      //this.delaisChargement();
      this.chargement = true;
      let lien = document.URL;
      let lien2 = lien.split("/").pop();
      if (lien2 !== "0") {
        this.id_import = lien2;
      }
      const url =
        "https://www.rab-tech.com/dev_mode/js/api/get_all_events.php?id=" +
        this.id_import;
      axios
        .get(url)
        .then((response) => {
          this.reponx_code = response.data.code;
          this.reponx = response.data.events;
        })
        .catch((error) => {
          console.log(error);
          this.echec = true;
        })
        .finally(() => {
          if (this.reponx_code == 200) {
            this.chargement = false;
            this.firstElement();
            this.echec = false;
          }
          if (this.reponx_code == 300) {
            this.chargement = false;
            this.echec = false;
            this.noresult = true;
          }
        });
    },
    recH: async function () {
      this.noresult = false;
      this.chargement = true;
      console.log(choix);
      if (choix !== "") {
        this.id_membre = choix;
        //alert(choix)
      }
      const url =
        "https://www.rab-tech.com/dev_mode/js/api/get_all_events.php?theme=" +
        this.search;
      const res = await axios.get(url);
      this.reponx = res.data.events;
      this.choix = "";
      this.reponx_code = res.data.code;
      if (this.reponx_code == 200) {
        this.chargement = false;
        this.firstElement();
        this.echec = false;
      }
      if (this.reponx_code == 300) {
        this.chargement = false;
        this.echec = false;
        this.noresult = true;
      }
    },
    exporteur(selection) {
      tabElemen = selection;
      return console.log(selection);
    },
    exporteurM(selection) {
      //choix=selection
      let vm = this;
      vm.$router.push({ path: `/membre-detail/${selection} ` });
      return console.log(selection);
    },
    del: async function () {
      this.chargement = true;
      this.erreur = false;
      const SUP = new FormData();
      SUP.set("id", this.selectitem.id);
      axios
        .post("https://www.rab-tech.com/dev_mode/js/api/delete_event.php", SUP)
        .then((response) => {
          console.log(response);
          this.reponx_code = response.data.code;
        })
        .catch((error) => {
          console.log(error);
          this.msg = "Echec de la supression. Veillez réessayer svp !";
          this.erreur = true;
          this.chargement = false;
        })
        .finally(() => {
          if (this.reponx_code == "201") {
            this.supprimer = true;
            this.chargement = false;
            this.makeGetRequest();
            this.supprimer = false;
            this.msg2 = "L'évenement à bien été supprimé.";
          } else {
            this.msg = "Echec de la supression. Veillez réessayer svp !";
            this.erreur = true;
            this.chargement = false;
          }
        });
    },
    allmembre: async function () {
      this.event_id = this.selectitem.id;
      const rex = await axios.get(
        "https://www.rab-tech.com/dev_mode/js/api/get_all_membres.php?id_event=" +
          this.event_id
      );
      this.all = rex.data.membres;
      this.loader = false;
    },
    deleteMembre: async function () {
      const SUP = new FormData();
      SUP.set("id_event", this.selectitem.id);
      SUP.set("id_participant", this.id_membre);
      axios
        .post(
          "https://www.rab-tech.com/dev_mode/js/api/delete_participant_event.php",
          SUP
        )
        .then((response) => {
          console.log(response);
          this.reponx_code = response.data.code;
        })
        .catch((error) => {
          console.log(error);
          this.MSG = "Echec de la supression. Veillez réessayer svp !";
          this.voir = "echec";
        })
        .finally(() => {
          if (this.reponx_code == "201") {
            //alert (this.reponx_code)
            window.location.reload();
          } else {
            this.MSG = "Echec de la supression. Veillez réessayer svp !";
            this.voir = "echec";
          }
        });
    },
    ajoutParticipant(info_id) {
      this.loader = true;
      this.ajouteur = info_id;
      this.ajt = true;
      const AJOUT = new FormData();
      AJOUT.set("id_event", this.selectitem.id);
      AJOUT.set("id_participant", this.ajouteur);
      axios
        .post(
          "https://www.rab-tech.com/dev_mode/js/api/add_participant_event.php",
          AJOUT
        )
        .then((response) => {
          console.log(response);
          this.reponx_code = response.data.code;
        })
        .catch((error) => {
          console.log(error);
          this.msg = "Echec de l'ajout du membre. Veillez réessayer svp !";
          this.erreur = true;
          this.chargement = false;
          this.loader = false;
        })
        .finally(() => {
          if (this.reponx_code == "201") {
            this.allmembre();
            //window.location.reload();
          } else {
            this.msg = "Echec de l'ajout du membre. Veillez réessayer svp !";
            this.erreur = true;
            this.chargement = false;
            this.loader = false;
          }
        });
    },
    ressayer: function () {
      this.recharg = false;
    },
    delaisChargement: function () {
      if (this.chargement) {
        setTimeout(this.ressayer(), 30000);
      }
    },
    rechercher: function () {
      return this.reponx.filter((item) => {
        item.titre.toLowerCase().includes(this.search.toLowerCase());
        this.elementTitre = item.titre
          .toLowerCase()
          .includes(this.search.toLowerCase());
        if (this.elementTitre) {
          return this.elementTitre;
        } else {
          this.elementTypeActivite = item.type_activite
            .toLowerCase()
            .includes(this.search.toLowerCase());
          this.elementDescription = item.description
            .toLowerCase()
            .includes(this.search.toLowerCase());
          this.elementLieu = item.lieu
            .toLowerCase()
            .includes(this.search.toLowerCase());

          if (this.elementDescription) {
            return this.elementDescription;
          }
          if (this.elementTypeActivite) {
            return this.elementTypeActivite;
          }
          if (this.elementLieu) {
            return this.elementLieu;
          }
        }
      });
    },
  },
  created() {
    this.makeGetRequest();
    let elementUser = JSON.parse(sessionStorage.getItem("authUser"));
    this.typeCompte = elementUser.type;
  },
  computed: {
    filteredEvenement() {
      return this.rechercher();
    },
    monFiltre: function () {
      return this.reponx.filter((item) => {
        if (this.filtreur == "titre") {
          return item.titre.toLowerCase().match(this.search.toLowerCase());
        }
        if (this.filtreur == "type") {
          return item.type_activite
            .toLowerCase()
            .match(this.search.toLowerCase());
        }
        if (this.filtreur == "lieu") {
          return item.lieu.toLowerCase().match(this.search.toLowerCase());
        }
      });
    },
  },
};
</script>
